.Program-categories{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1.2rem;
    border-radius: 2rem;
    color: white;
    z-index: 1;
    
}

.category{
    display: flex;
    gap: 1rem;
    background-color: rgb(37, 37, 40);
    flex-direction: column;
    padding: 2rem;
    border-radius: 1rem;
    /* height: 13rem; */
}

.category:hover{
    background: var(--planCard);
    z-index: 1;
    cursor:pointer;
}

.category>:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    fill: white;
}

.category>:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: center;
}




