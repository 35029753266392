.subIntro{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
    margin-bottom: 2rem;
}

.subIntro>span:nth-of-type(1){
display: flex;
gap: 8px;
font-size: 5rem;
font-style: italic;
}

.subIntro>span:nth-of-type(2){
    display: flex;
    gap: 8px;
    font-weight: bold;
    color: aliceblue;
    font-size: 5rem;
    font-style: italic;
    text-decoration: underline;
}

.subIntro>span:nth-of-type(3){
    display: flex;
    font-size: 5rem;
    font-style: italic;
    gap: 8px;
}