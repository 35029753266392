.Advert{
    margin-top: 5rem;
    background-color: rgb(37, 37, 40);
    border-radius: 3rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Advert>span{
    z-index: 2;
}

.Advert>div{
    position: absolute;
    background-color: rgb(186, 135, 39);
    width: 6.9rem;
    height: 80%;
    left: 8px;
    border-radius: 2rem;
    z-index: 1;
}