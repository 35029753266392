.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 10rem;
    height: 3rem;
    
}

.header-menu{
    display: flex;
    list-style: none;
    gap: 2.1rem;
    color:rgb(252, 255, 255);

}


.header-menu>li:hover{
    cursor: pointer;
    color: rgb(228, 136, 55);
}