.subCards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    gap: 3rem;
    border-radius: 2rem;
    color: white;
    z-index: 1;
    margin: 3rem;
    
}

.feature :nth-last-of-type(1){
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
}

.categories{
    display: flex;
    gap: 1rem;
    background-color: rgb(37, 37, 40);
    flex-direction: column;
    padding: 2rem;
    border-radius: 1rem;
    background: var(--caloryCard);
    width: 18rem;
}

.categories:nth-last-of-type(2){
    background: rgb(37, 37, 40);
    transform: scale(1.2);
}



.categories:hover{
    background: var(--planCard);
    z-index: 1;
    cursor:pointer;
}
.categories > :nth-last-of-type(1){
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.categories>:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    width: 5rem;
    height: 3rem;
    
    fill: #f48915;
}

.feature :nth-child(2){
    font-size: 1rem;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serifs;
    font-weight: 400;
}



.categories>:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.categories>:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.categories>:nth-child(4){
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.categories > .button{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    justify-content: center;
    height: 2rem;
    align-items: center;
}
