.Hero-Buttons{
    display: flex;
    gap: 2rem;
    font-weight: normal; 
    margin-top: 2rem;
}

.Hero-Buttons>:nth-of-type(1){
    background-color: rgb(186, 135, 39);
    color: azure;
    width: 13rem;
    border-radius: 1rem;
}

.Hero-Buttons>:nth-of-type(2){
    background-color: white;
    color: rgb(186, 135, 39);
    width: 13rem;
    border-radius: 0.7rem;
}
